import { ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import styles from './StepBankId.module.scss';
import cms from '../../../data/cms.json';
import config from '../../../config.json';
import { AppContext } from '../../Layout/Layout';
import { Step } from '../../Step/Step';
import { getBaseUrl } from '../../../utils/getBaseUrl';
import axios from 'axios';
import { ErrorForm } from '../../ErrorForm/ErrorForm';
import { Loader } from '../../Loader/Loader';
import { setCompleted } from '../../../utils/setCompleted';
import { Button } from '../../Button/Button';
import { ResponseErrorDataType } from '../../../enums/responseErrorDataType';
import { Box } from '../../Box/Box';
import { Title } from '../../Title/Title';
import { UnorderedList } from '../../UnorderedList/UnorderedList';
import { resetProcess } from '../../../utils/resetProcess';

interface StepBankIdProps {
    className?: string;
}

export const StepBankId = ({ className }: StepBankIdProps): ReactElement | null => {
    const ctx = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [bankIdUrl, setBankIdUrl] = useState<string | null>(null);
    const [bankIdError, setbankIdError] = useState(false);
    const [dataDiscrepancy, setDataDiscrepancy] = useState<string[]>([]);
    const BASE_URL = getBaseUrl();

    useEffect(() => {
        ctx.setIsBackButtonVisible(false);
    }, []);

    useEffect(() => {
        setbankIdError(false);

        const hash = location.hash;
        const newHash = '?' + hash.substring(1);
        const params = new URLSearchParams(newHash);

        const access_token = params.get('access_token');
        const token_type = params.get('token_type');
        const id_token = params.get('id_token');
        const state = params.get('state');
        const expires_in = params.get('expires_in');

        axios
            .post(
                `${BASE_URL}api/online/approvals/approve-by-bank-id`,
                {
                    IpAddress: '',
                    AccessToken: access_token,
                    TokenTypeText: token_type,
                    StateText: state,
                    ExpiresInText: expires_in,
                    IdTokenText: id_token,
                },
                { timeout: config.FETCH_TIMEOUT }
            )
            .then((response) => {
                if (response && state) {
                    ctx.setFormResult(response.data);
                    setCompleted(9);
                    navigate(`/${config.SLUGS.STEP_CONFIRMDATA_SLUG}/`);
                } else {
                    setbankIdError(true);
                }
            })
            .catch((error) => {
                setbankIdError(true);
                if (
                    error.code === 'ERR_BAD_REQUEST' &&
                    error.response.data.ResponseErrorDataType === ResponseErrorDataType.ValidationError &&
                    error.response.data.Errors.length > 0
                ) {
                    const discrepancy: string[] = [];
                    error.response.data.Errors.forEach((err: { MessageShortTranslated: string | null }) => {
                        discrepancy.push(err.MessageShortTranslated ?? '');
                    });
                    setDataDiscrepancy(discrepancy);
                } else {
                    console.error(error);
                }
            });
    }, [location]);

    useEffect(() => {
        // ### Set url for tryAgainBankID button
        if (!bankIdUrl) {
            const baseUrl = window.location.origin;
            const url = ctx.initData?.OnlineConfigurationData.BankIdApprovalUrl;
            const targetUrl = baseUrl + '/' + config.SLUGS.STEP_BANK_ID_SLUG;
            const externalId = ctx.formResult.ExternalId;
            const replaceTargetUrl = '{#returnUrl}';
            const replaceTargetApprover = '{#approverId}';

            if (url && externalId) {
                setBankIdUrl(url?.replace(replaceTargetUrl, targetUrl).replace(replaceTargetApprover, externalId));
            }
        }
    }, [ctx, bankIdUrl]);

    if (bankIdError) {
        if (dataDiscrepancy.length > 0) {
            return (
                <Step
                    title={cms.dataDiscrepancy.title}
                    text={cms.dataDiscrepancy.text}
                    className={cn(styles.wrapper, className)}
                >
                    <Box innerWrapper className="mb-6">
                        <Title tag="h3" className="mb-6">
                            {cms.dataDiscrepancy.boxTitle}
                        </Title>

                        <UnorderedList className={cn(styles.list, 'mb-4')} items={dataDiscrepancy} />
                    </Box>
                    <Button
                        className="mb-6"
                        variant="primary"
                        loading={submitting}
                        onClick={(e) => {
                            e?.preventDefault();
                            if (ctx.formResult) {
                                setSubmitting(true);
                                bankIdUrl && window && window.open(bankIdUrl, '_self');
                            }
                        }}
                    >
                        {cms.common.anotherBankIdButton}
                    </Button>
                    <div
                        onClick={() => {
                            resetProcess(navigate);
                        }}
                        className={cn(styles.resetProcessLink, 'text--underline')}
                    >
                        {cms.common.resetButton}
                    </div>
                    <ErrorForm />
                </Step>
            );
        } else {
            return (
                <Step
                    title={cms.bankIdError.title}
                    text={cms.bankIdError.text}
                    className={cn(styles.wrapper, className)}
                >
                    <Button
                        className="mb-6"
                        variant="primary"
                        loading={submitting}
                        onClick={(e) => {
                            e?.preventDefault();
                            if (ctx.formResult) {
                                setSubmitting(true);
                                bankIdUrl && window && window.open(bankIdUrl, '_self');
                            }
                        }}
                    >
                        {cms.common.tryAgainBankIdButton}
                    </Button>
                    <ErrorForm />
                </Step>
            );
        }
    }

    return (
        <Step
            title={cms.step_confirmdata.title}
            text={cms.step_confirmdata.text}
            className={cn(styles.wrapper, className)}
        >
            <Loader size="medium" />
        </Step>
    );
};
